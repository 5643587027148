import React, { useEffect } from 'react';
import { Col, Card, Row, Button } from 'reactstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { connect } from 'react-redux';
import { partial } from '../../../util/translation/translation';
import renderField from '../../../shared/components/custom/Field';
import validate from './validate';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import TextEditor from '../../../shared/components/text-editor/TextEditor';

const s = partial('shared');
const p = partial('UserTiers');

const UserTierForm = ({
  handleSubmit,
  tier,
  initialize,
  editorState,
  setEditorState,
  formValues,
}) => {
  useEffect(() => {
    if (tier) {
      const {
        createdAt, updatedAt, deletedAt, originalUrl, features, prices, description, ...tierValues
      } = tier;
      const blocksFromHTML = convertFromHTML(description || '');
      const htmlstate = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(htmlstate));
      const initObject = {
        ...tierValues,
        image: originalUrl
          ? {
            name: 'image',
            preview: originalUrl,
          }
          : null,
        pickedFeatures: features,
      };
      let completeObject = { ...initObject };
      if (prices.length) {
        completeObject = {
          ...completeObject,
          ...{
            price1: prices[0],
            // price3: prices[1],
            // price6: prices[2],
            price12: prices[3],
          },
        };
      }
      initialize(completeObject);
    }
  }, [tier]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Card>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{p('tierName')}</span>
              <div className="marginTop-5" />
              {console.log(initialize)}
              <Field
                name="name"
                disabled={tier?.name}
                component={renderField}
                placeholder={p('tierName')}
              />
            </div>
          </Col>
          {/* <Col lg={6} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{p('previousPrice')}</span>
              <div className="marginTop-5" />
              <Field
                name="previousPrice"
                component={renderField}
                placeholder={p('previousPrice')}
                type="number"
                disabled={!isPriceEditable}
              />
            </div>
          </Col> */}
        </Row>
        <Row>
          {/* <Col lg={3} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{p('oneMonthPrice')}</span>
              <div className="marginTop-5" />
              <Field
                name="price1"
                component={renderField}
                placeholder="One month price"
                type="number"
                disabled={!isPriceEditable}
              />
            </div>
          </Col> */}
          {/* <Col lg={3} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">Three month price</span>
              <div className="marginTop-5" />
              <Field
                name="price3"
                component={renderField}
                placeholder="Three month price"
                type="number"
                disabled={!isPriceEditable}
              />
            </div>
          </Col>
          <Col lg={3} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">Six month price</span>
              <div className="marginTop-5" />
              <Field
                name="price6"
                component={renderField}
                placeholder="Six month price"
                type="number"
                disabled={!isPriceEditable}
              />
            </div>
          </Col> */}
          {/* <Col lg={3} md={12} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{p('yearlyPrice')}</span>
              <div className="marginTop-5" />
              <Field
                name="price12"
                component={renderField}
                placeholder="One year price"
                type="number"
                disabled={!isPriceEditable}
              />
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{s('description')}</span>
              <div className="marginTop-5" />
              <div className="form__form-group form__form-group-id">
                <TextEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/*
        <DragNDropField
          name="pickedFeatures"
          label={p('tierScreens')}
          options={availableFeatures}
          initialValue={tier?.features}
        /> */}
        <Row>
          <Col xl={6} lg={6} md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('image')} (200px/200px)</span>
              <Field name="image" component={renderDropZoneField} crop="TIER_CROP" />
            </div>
          </Col>
          <Col xl={6} lg={6} md={6}>
            <div className="w-100">
              {formValues?.image && <img className="tier-logo" src={formValues?.image?.croppedImage || formValues?.image?.preview} alt="" />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ marginTop: '15px' }}>
            <Button type="submit" color="primary">
              {s('save')}
            </Button>
            <Link
              className="color-unset"
              to="/user_tiers"
            >
              <Button type="button">
                {s('close')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    </form>
  );
};
const reduxF = reduxForm({
  form: 'user-tier-form',
  validate,
});
const mapStateToProps = (state) => ({
  formValues: getFormValues('user-tier-form')(state),
});

export default connect(mapStateToProps)(reduxF(UserTierForm));

