/*
  READ ME

  Path: The path to the url

  Exact: Decides wheter the path is exacts. If it is set to false, it uses startsWith function.

  Permissions:
    Uses a 2d array. This is to support both AND aswell as OR permissions.
*/

const permissions = [
  {
    path: '/',
    permissions: [['access-admin-panel'], ['super-admin']],
  },
  {
    path: '/clubadmin',
    exact: false,
    permissions: [['super-admin']],
  },
  {
    path: '/news',
    permissions: [['access-news'], ['super-admin']],
  },
  {
    path: '/sponsor_news',
    permissions: [['access-news'], ['super-admin']],
  },
  {
    path: '/tickets',
    permissions: [
      ['access-season-tickets', 'access-tickets'], ['super-admin'],
    ],
  },
  {
    path: '/matches',
    permissions: [['access-matches'], ['super-admin']],
  },
  {
    path: '/discounts',
    permissions: [['access-settings'], ['super-admin']],
  },
  {
    path: '/users',
    permissions: [['access-users'], ['super-admin']],
  },
  {
    path: '/companies',
    permissions: [['access-companies'], ['super-admin']],
  },
  {
    path: '/messages',
    permissions: [['access-messages'], ['super-admin']],
  },
  {
    path: '/cnc',
    permissions: [['access-kiosks'], ['super-admin']],
  },
  {
    path: '/auctions',
    permissions: [['access-kiosks'], ['super-admin']],
  },
  {
    path: '/kiosk',
    permissions: [['access-kiosk-sales'], ['super-admin']],
  },
  {
    path: '/box',
    permissions: [['access-sales'], ['super-admin']],
  },
  {
    path: '/invoices',
    permissions: [
      ['access-payments', 'super-admin'],
      ['access-teams', 'super-admin'],
      ['access-matches', 'super-admin'],
    ],
  },
  {
    path: '/vendors',
    permissions: [
      ['access-payments'],
      ['access-teams'],
      ['super-admin'],
    ],
  },
  {
    path: '/reports',
    permissions: [
      ['access-reports'],
      ['access-teams'],
      ['access-matches'],
      ['super-admin'],
    ],
  },
  {
    path: '/seasons',
    exact: false,
    permissions: [['access-matches'], ['super-admin']],
  },
  {
    path: '/stadiums',
    permissions: [['access-stadium', 'access-settings'], ['super-admin']],
  },
  {
    path: '/seasons',
    permissions: [['access-matches'], ['super-admin']],
  },
  {
    path: '/settings',
    permissions: [['access-settings', 'access-tickets'], ['super-admin']],
  },
  {
    path: '/packages',
    permissions: [['access-settings'], ['super-admin']],
  },
  {
    path: '/balances',
    permissions: [['access-settings'], ['super-admin']],
  },
  {
    path: '/players',
    permissions: [['access-news'], ['super-admin']],
  },
  {
    path: '/events',
    permissions: [['access-matches'], ['super-admin']],
  },
  {
    path: '/external_stores',
    permissions: [['access-kiosk-sales'], ['super-admin']],
  },
  {
    path: '/spotlight',
    permissions: [['access-settings'], ['super-admin']],
  },
  {
    path: '/profile',
    permissions: [['access-settings'], ['super-admin']],
  },
];

export default permissions;
