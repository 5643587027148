import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { toast } from 'react-toastify';
import PlayerForm from './components/PlayerForm';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';

const PlayerFormPage = () => {
  const { playerID, teamId } = useParams();
  const history = useHistory();
  const [loadingPlayer, setLoadingPlayer] = useState(!!playerID);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [player, setPlayer] = useState(undefined);
  const [sponsors, setSponsors] = useState([]);
  const p = partial('PlayerFormPage');
  const s = partial('shared');

  const optionsMap = (data) => ({
    ...data,
    value: data.id.toString(),
    label: data.name,
  });

  const getInitialData = async () => {
    setLoadingPlayer(true);
    try {
      const [resSponsors, resTeamPlayers, resPlayer] = await Promise.all([
        Api.players.getSponsors(),
        Api.players.all(teamId),
        playerID && Api.players.getPlayer(playerID),
      ]);
      setTeamPlayers(resTeamPlayers.data);
      const mappedSponsors = resSponsors.data.map(optionsMap);
      setSponsors(mappedSponsors);
      if (playerID) {
        setPlayer(resPlayer.data);
        if (resPlayer.data.image) {
          resPlayer.data.image.preview = resPlayer?.data?.image?.url;
        }
      }
    } catch (err) {
      toast.error(p('fetchingPlayerFailed'));
    }
    setLoadingPlayer(false);
  };

  useEffect(() => {
    getInitialData();
  }, [playerID, teamId]);
  const uploadRemoveImage = async (values, fieldName, playerId) => {
    const upload = (await values[fieldName]?.promise?.('player', fieldName, playerId) || values[fieldName]?.preview?.includes('blob'));
    if (!upload && !values[fieldName]?.preview) {
     await values[fieldName]?.remove?.('player', fieldName, playerId, values[fieldName]?.hash);
    }
   };
  const onSubmitPlayer = async (values) => {
    const mergePlayer = values.mergePlayer?.value;
    // eslint-disable-next-line no-unreachable
    const isNullCrop = val => JSON.stringify(val) === '{"unit":"%","aspect":1,"x":0,"y":0,"width":0,"height":0}';
    const payload = {
      mergePlayerId: mergePlayer ? +mergePlayer : undefined,
      externalProviderId: player?.externalProviderId,
      firstname: values.firstname,
      lastname: values.lastname,
      infoUrl: values.infoUrl ? values.infoUrl : null,
      fullname: `${values.firstname} ${values.lastname}`,
      jerseyName: values.jerseyName || values.lastname,
      position: values.position,
      jerseyNumber: values.jerseyNumber,
      nationality: values.nationality?.iso || null,
      dateOfBirth: values.dateOfBirth && moment(values.dateOfBirth).format('YYYY-MM-DD'),
      imageCrop: values.imageUrl?.crop && !isNullCrop(values.imageUrl.crop) ? values.imageUrl.crop : null,
      companyId: values.company ? values.company.value : null,
      shots: values.shots,
      weight: values.weight ? Number(values.weight) : undefined,
      height: values.height ? Number(values.height) : undefined,
      active: values.active,
    };
    setLoadingPlayer(true);
    try {
      if (playerID) {
        await Api.players.updatePlayer({
          id: +playerID,
          ...payload,
        });
		await uploadRemoveImage(values, 'imageUrl', playerID);
        toast.success(p('playerUpdated'));
        if (mergePlayer) {
          history.goBack();
          toast.success(p('playerMerged'));
        } else {
		  history.goBack();
        }
      } else {
          payload.externalProviderId = `00${Math.floor(Math.random() * 1000000000)}`;
          payload.currentTeam = { id: teamId };
          payload.id = Math.floor(Math.random() * 1000000000);
          const { data } = await Api.players.createPlayer(payload);
		  await uploadRemoveImage(values, 'imageUrl', data.id);
          toast.success(p('playerCreated'));
          history.goBack();
      }
    } catch (err) {
      setLoadingPlayer(false);
      toast.error(err || s('somethingWentWrong'));
    }
  };
  return (
    <Container>
      <Loading loading={loadingPlayer} />
      <Row>
        <Col>
          <h3 className="page-title">{playerID ? s('save') : s('create')} {s('player')}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <PlayerForm
                onSubmit={onSubmitPlayer}
                player={player}
                teamPlayers={teamPlayers}
                sponsors={sponsors}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PlayerFormPage;
