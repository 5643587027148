import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import AuthService from '../../_app/AuthService';
import t, { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
import Config from '../../../config';

const p = partial('SidebarContent');
const s = partial('shared');
const m = partial('matchCenter');

const SidebarContent = ({ me, dispatch, onClick, collapse }) => {
  const config = useConfig();
  const [active, setActive] = useState('');
  const hideSidebar = (e) => {
    setActive(e);
    onClick();
  };

  const logOut = () => {
    AuthService.signout(dispatch);
  };

  const label = (title) => {
    const styles = { position: 'fixed', left: collapse ? 2 : 0, marginTop: -28, fontSize: 10, padding: '5px 7px 5px 20px', background: '#fff', color: '#656777' };
    return (
      <span style={styles}>{title}</span>
    );
  };

  const matchSearchQuery = (name) => {
    const { search } = window.location;
    return search?.replace('?', '')?.split('&')?.includes(name);
  };

  const matchPathname = (name) => {
    const { pathname } = window.location;
    return pathname?.split('/')?.includes(name);
  };

  const renderPermissions = () => {
    const { permissions } = me ?? [];
    if (permissions) {
      const features = config?.featureFlags;
      let matches;
      let users;
      let packages;
      let sales;
      let reports;
      let offers;
      let news;
      let dashboard;
      const subMenuStyles = { background: '#F5FAFF' };

      const contentAccess = permissions.includes('access-matches') || permissions.includes('access-news');

      const ticketsAccess = permissions.includes('access-tickets') || permissions.includes('access-season-tickets');
      const matchAndSeasonAccess = permissions.includes('access-matches');
      const newsAccess = permissions.includes('access-news');
      const messagesAccess = permissions.includes('access-messages');

      const boxOfficeAccess = permissions.includes('access-sales');
      const packagesAccess = permissions.includes('access-settings');
      const importAccess = permissions.includes('access-tickets');
      const stadiumAccess = permissions.includes('access-stadium') || permissions.includes('access-settings');
      const settingsAccess = permissions.includes('access-settings');

      const userAccess = permissions.includes('access-users');
      const companyAccess = permissions.includes('access-companies');

      const kioskAccess = permissions.includes('access-kiosks');
      const kioskSaleAccess = permissions.includes('access-kiosk-sales');

      const invoiceAccess = permissions.includes('access-payments') && permissions.includes('access-teams') && permissions.includes('access-matches');
      const reportsAccess = permissions.includes('access-reports') && permissions.includes('access-teams') && permissions.includes('access-matches');

      if (contentAccess) {
        matches = (
          <ul key="matches-sidebar" className="sidebar__block">
            {label(s('content'))}
            {matchAndSeasonAccess && Config.monolithEnabled && <SidebarLink title={p('matches')} active={active === 'matches'} icon="calendar-empty" route="/matches" onClick={() => { hideSidebar('matches'); }} />}
            {matchAndSeasonAccess && !Config.monolithEnabled && <SidebarLink title={m('matchCenter')} newBadge active={active === 'matches'} icon="calendar-empty" route="/matches/upcoming" onClick={() => { hideSidebar('matches'); }} />}
            <SidebarLink title={p('events')} active={active === 'events' || matchPathname('events')} open={active.split('_')[0] === 'events'} icon="calendar-full" route="/events" onClick={() => { hideSidebar('events'); }} />
            {(active.split('_')[0] === '1events' && matchPathname('events')) &&
            <>
              <SidebarLink title={t('EventsPage.eventCategories')} active={matchSearchQuery('tab=2')} icon="dot" style={subMenuStyles} route="/events?tab=2" onClick={() => { hideSidebar('events_2'); }} />
              <SidebarLink title={s('communication')} icon="dot" active={matchSearchQuery('tab=3')} style={subMenuStyles} route="/events?tab=3" onClick={() => { hideSidebar('events_3'); }} />
            </>
              }
            <SidebarLink title={p('offers')} icon="gift" open={active.split('_')[0] === 'offers'} active={active === 'offers'} route="/offers" onClick={() => { hideSidebar('offers'); }} />
            {active.split('_')[0] === '1offers' &&
              <>
                <SidebarLink title={s('communication')} icon="dot" active={active === 'offers_2'} style={subMenuStyles} route="/offers?tab=2" onClick={() => { hideSidebar('offers_2'); }} />
              </>
            }
            {newsAccess &&
              <>
                <SidebarLink title={s('news')} open={active.split('_')[0] === 'news'} active={active === 'news'} icon="news" route="/news?tab=1" onClick={() => { hideSidebar('news'); }} />
                {active.split('_')[0] === '1news' &&
                  <>
                    <SidebarLink title={s('communication')} icon="dot" active={active === 'news_2'} style={subMenuStyles} route="/news?tab=2" onClick={() => { hideSidebar('news_2'); }} />
                    <SidebarLink title={s('settings')} icon="dot" active={active === 'news_3'} style={subMenuStyles} route="/news?tab=3" onClick={() => { hideSidebar('news_3'); }} />
                  </>
                }
              </>
            }
            <SidebarLink title={s('players')} open={active.split('_')[0] === 'players'} active={active === 'players'} icon="walk" route="/players?tab=1" onClick={() => { hideSidebar('players'); }} />
            {active.split('_')[0] === '1players' &&
              <>
                <SidebarLink title={s('settings')} icon="dot" active={active === 'players_2'} style={subMenuStyles} route="/players?tab=2" onClick={() => { hideSidebar('players_2'); }} />
                <SidebarLink title={t('PlayerListPage.transferPlayers')} icon="dot" active={active === 'players_3'} style={subMenuStyles} route="/players?tab=3" onClick={() => { hideSidebar('players_3'); }} />
              </>
            }
          </ul>
        );
      }

      if ((ticketsAccess || boxOfficeAccess || packagesAccess) && features?.includes('tickets') && Config.monolithEnabled) {
        packages = (
          <ul key="tickets-sidebar" className="sidebar__block">
            {label(s('tickets'))}
            {ticketsAccess &&
              <>
                <SidebarLink title={s('tickets')} open={active.split('_')[0] === 'tickets'} active={active === 'tickets'} icon="ticket" route="/tickets?tab=1" onClick={() => { hideSidebar('tickets'); }} />
                {active.split('_')[0] === '1tickets' &&
                  <>
                    <SidebarLink title={t('TicketsPage.matchTickets')} icon="dot" active={active === 'tickets_2'} style={subMenuStyles} route="/tickets?tab=2" onClick={() => { hideSidebar('tickets_2'); }} />
                  </>
                }
                {boxOfficeAccess &&
                  <SidebarLink title={p('boxOffice')} active={active === 'boxOffice'} icon="cashier" route="/box" onClick={() => { hideSidebar('boxOffice'); }} />
                }
                <SidebarLink title={p('packages')} active={active === 'package'} icon="box" route="/packages" onClick={() => { hideSidebar('package'); }} />
                <SidebarLink title={s('discounts')} open={active.split('_')[0] === 'discounts'} active={active === 'discounts'} icon="ticket" route="/discounts" onClick={() => { hideSidebar('discounts'); }} />
                {active.split('_')[0] === '1discounts' &&
                  <>
                    <SidebarLink title={t('DiscountsPage.discountCodes')} icon="dot" active={active === 'discounts_2'} style={subMenuStyles} route="/discounts?tab=2" onClick={() => { hideSidebar('discounts_2'); }} />
                  </>
                }
                {(features?.includes('tickets')) &&
                  <>
                    <SidebarCategory title={p('settings')} icon="cog">
                      <SidebarLink title={s('priceGroups')} route="/settings?tab=2" onClick={() => { hideSidebar(''); }} />
                      <SidebarLink title={p('stadiums')} route="/stadiums?tab=1" onClick={() => { hideSidebar(''); }} />
                      <SidebarLink title={p('controlImages')} route="/settings?tab=4" onClick={() => { hideSidebar(''); }} />
                      {importAccess ?
                        <SidebarLink title={s('import')} icon="paste" route={`/settings?tab=${settingsAccess ? 8 : 1}`} onClick={() => { hideSidebar(''); }} />
                        : undefined
                      }
                    </SidebarCategory>
                  </>
                }
              </>
            }
          </ul>
        );
      }

      if (permissions.includes('access-news')) {
        news = (
          <ul key="news-sidebar" className="sidebar__block">
            {label('App')}
            <SidebarLink title={p('appLayout')} active={active === 'applayout'} icon="phone" route="/app_layout" onClick={() => { hideSidebar('applayout'); }} />
            <SidebarLink title={s('spotlight')} active={active === 'spotlight'} collapse={collapse} icon="picture" route="/spotlight" onClick={() => { hideSidebar('spotlight'); }} />
            {messagesAccess &&
              <>
                <SidebarLink title={p('sendMessages')} open={active.split('_')[0] === 'messages'} active={active === 'messages'} icon="bullhorn" route="/messages" onClick={() => { hideSidebar('messages'); }} />
                {active.split('_')[0] === '1messages' &&
                  <>
                    <SidebarLink title={t('MessagesStatsPage.pushStats')} icon="dot" active={active === 'messages_2'} style={subMenuStyles} route="/messages?tab=2" onClick={() => { hideSidebar('messages_2'); }} />
                    <SidebarLink title={t('MessagesPage.appMessage')} icon="dot" active={active === 'messages_3'} style={subMenuStyles} route="/messages?tab=3" onClick={() => { hideSidebar('messages_3'); }} />
                    <SidebarLink title={s('communication')} icon="dot" active={active === 'messages_4'} style={subMenuStyles} route="/messages?tab=4" onClick={() => { hideSidebar('messages_4'); }} />
                  </>
                }
              </>
            }
          </ul>
        );
      }

      if ((userAccess || companyAccess)) {
        users = (
          <ul key="users-sidebar" className="sidebar__block">
            {label(s('users'))}
            {userAccess &&
              <>
                <SidebarLink title={s('users')} open={active.split('_')[0] === 'users'} active={active === 'users'} icon="user" route="/users?tab=1" onClick={() => { hideSidebar('users'); }} />
                {active.split('_')[0] === '1users' &&
                  <>
                    <SidebarLink title={s('userGroups')} icon="dot" active={active === 'users_2'} style={subMenuStyles} route="/users?tab=2" onClick={() => { hideSidebar('users_2'); }} />
                    <SidebarLink title={s('roles')} icon="dot" active={active === 'users_3'} style={subMenuStyles} route="/users?tab=3" onClick={() => { hideSidebar('users_3'); }} />
                  </>
                }
              </>
            }
            {userAccess &&
              <SidebarLink title={s('userTiers')} active={active === 'userTiers'} icon="phone" route="/user_tiers?tab=1" onClick={() => { hideSidebar('userTiers'); }} />
            }
            {companyAccess &&
              <>
                <SidebarLink title={s('companies')} open={active.split('_')[0] === 'companies'} active={active === 'companies'} icon="apartment" route="/companies?tab=1" onClick={() => { hideSidebar('companies'); }} />
                {active.split('_')[0] === '1companies' &&
                  <>
                    <SidebarLink title={s('settings')} icon="dot" active={active === 'companies_2'} style={subMenuStyles} route="/companies?tab=2" onClick={() => { hideSidebar('companies_2'); }} />
                    <SidebarLink title="App sponsor" icon="dot" active={active === 'companies_3'} style={subMenuStyles} route="/companies?tab=3" onClick={() => { hideSidebar('companies_3'); }} />
                    <SidebarLink title={t('Category.goalService')} icon="dot" active={active === 'companies_4'} style={subMenuStyles} route="/companies?tab=4" onClick={() => { hideSidebar('companies_4'); }} />
                  </>
                }
              </>
            }
          </ul>
        );
      }


      if (kioskAccess || kioskSaleAccess || boxOfficeAccess) {
        sales = (
          <ul key="sales-sidebar" className="sidebar__block">
            {label('Premium')}
            {(kioskAccess && kioskSaleAccess) && Config.monolithEnabled &&
              <SidebarLink title={`${p('c&c')} - ${s('kiosk')}`} active={active === 'cnc'} disabled={!features?.includes('cnc')} premium collapse={collapse} icon="cart" route="/cnc?tab=1" onClick={() => { hideSidebar('cnc'); }} />
            }
            {(kioskSaleAccess && kioskAccess) && Config.monolithEnabled &&
              <SidebarLink title={`${p('c&c')} - ${p('salesPanel')}`} active={active === 'cnc_2'} disabled={!features?.includes('cnc')} premium collapse={collapse} icon="cart" route="/kiosk" onClick={() => { hideSidebar('cnc_2'); }} />
            }
            {kioskAccess && (
              <SidebarLink title={p('auctions')} active={active === 'auctions'} disabled={!features?.includes('auction')} premium collapse={collapse} icon="hammer2" route="/auctions" onClick={() => { hideSidebar('auctions'); }} />
            )}

            {kioskSaleAccess && Config.monolithEnabled &&
              <SidebarLink title={p('externalStores')} active={active === 'externalStores'} disabled={!features?.includes('externalStore')} premium collapse={collapse} icon="store" route="/external_stores" onClick={() => { hideSidebar('externalStores'); }} />
            }
            <SidebarLink title={s('lottery')} active={active === 'lottery'} disabled={!features?.includes('lottery')} premium collapse={collapse} icon="ticket" route="/lottery" onClick={() => { hideSidebar('lottery'); }} />
            {ticketsAccess &&
              <SidebarLink title={p('externalTickets')} active={active === 'externalTickets'} disabled={!features?.includes('externalTickets')} icon="ticket" premium collapse={collapse} route="/tickets/external" onClick={() => { hideSidebar('externalTickets'); }} />
            }
          </ul>
        );
      }

      const settings = (
        <ul key="settings" className="sidebar__block">
          <SidebarCategory title={s('settings')} icon="cog">
            <>
              {settingsAccess && <SidebarLink title={t('shared.vendors')} route="/vendors" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && <SidebarLink title={t('ClubProfilePage.clubProfile')} route="/profile" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && Config.monolithEnabled && <SidebarLink title={s('seasons')} route="/seasons" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && Config.monolithEnabled && <SidebarLink title={s('team')} route="/settings?tab=1" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && Config.monolithEnabled && <SidebarLink title={s('priceGroups')} route="/settings?tab=2" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && Config.monolithEnabled && <SidebarLink title={p('matchTypes')} route="/settings?tab=3" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && Config.monolithEnabled && <SidebarLink title={t('settingsObjectsShared.controllImages')} route="/settings?tab=4" onClick={() => { hideSidebar(''); }} />}
              {settingsAccess && <SidebarLink title={s('terms')} route={`/settings?tab=${Config.monolithEnabled ? '5' : '1'}`} onClick={() => { hideSidebar(''); }} />}
              {ticketsAccess && Config.monolithEnabled && <SidebarLink title="Import" route="/settings?tab=6" onClick={() => { hideSidebar(''); }} />}
            </>
          </SidebarCategory>
        </ul>
      );


      if (
        ((invoiceAccess) ||
        (reportsAccess) ||
        (stadiumAccess) ||
        (settingsAccess) ||
        (permissions.includes('access-settings'))) && Config.monolithEnabled
      ) {
        reports = (
          <ul key="settings-sidebar" className="sidebar__block">
            {reportsAccess &&
              <SidebarLink title={p('reports')} icon="chart-growth" route="/reports?tab=1" onClick={() => { hideSidebar(''); }} />
            }
          </ul>
        );
      }

      if (permissions.includes('access-settings')) {
        dashboard = (
          <ul key="dashboard-sidebar" className="sidebar__block border-none">
            {permissions.includes('super-admin') &&
              <SidebarLink title="Club administration" onClick={() => { hideSidebar(''); }} route="/clubadmin" />
            }
            <SidebarLink title={s('dashboard')} active={active === 'dashboard'} icon="home" route="/" onClick={() => { hideSidebar('dashboard'); }} />
          </ul>
        );
      }

      return [dashboard, matches, packages, news, sales, users, reports, settings, offers];
    }

    return '';
  };

  return (
    <div className="sidebar__content">
      {renderPermissions()}
      <ul className="sidebar__block">
        <SidebarLink title={s('logOut')} icon="exit" route="/log_in" onClick={logOut} />
      </ul>
    </div>
  );
};

SidebarContent.defaultProps = {
  me: null,
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  me: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  balance: PropTypes.shape({
    name: PropTypes.string.isRequired,
    hasFetched: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  enabledFeatures: PropTypes.shape({
    fansaldo: PropTypes.bool,
    packages: PropTypes.bool,
    hasFetched: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withRouter(connect(state => ({
  me: state.admin.me,
  balance: state.balance,
  enabledFeatures: state.enabledFeatures,
}))(SidebarContent));
