import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import PrizeForm from './PrizeForm';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';

const PrizeFormPage = () => {
  const { entityID: prizeId } = useParams();
  const history = useHistory();
  const [prize, setPrize] = useState(null);
  const [loading, setLoading] = useState(false);
  const s = partial('shared');

  const getPrize = async () => {
    setLoading(true);
    try {
      const prizeData = await Api.mvpPrize.getPrizeById(prizeId);
      setPrize(prizeData);
      setLoading(false);
    } catch (err) {
      toast.error(JSON.stringify(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (prizeId) {
        getPrize();
    }
  }, [prizeId]);


  const onPrizeSubmit = async (values) => {
    const payload = {
        title: values.title,
      description: values.description,
      image: values.headingImage.croppedBase64 || values.headingImage.preview,
    };
    if (values.headingImage?.croppedBase64) {
      const sizeInBytes = 4 * Math.ceil((values.headingImage.croppedBase64.length / 3)) * 0.5624896334383812;
      const sizeInKb = sizeInBytes / 1000;
      const convKbToKB = sizeInKb * 0.125;

      if (convKbToKB > 10000) {
        toast.error(s('imageTooLarge'));
        return;
      }
    }
    setLoading(true);
    try {
      if (prizeId) {
        await Api.mvpPrize.updatePrize(prizeId, payload);
      } else {
        await Api.mvpPrize.createPrize(payload);
      }
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    } finally {
        setLoading(false);
        history.replace('/matches/upcoming?tab=2');
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <Card>
            <CardBody className="px-0">
              <PrizeForm onSubmit={onPrizeSubmit} prizeId={prizeId} prize={prize} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrizeFormPage;
