import moment from 'moment';
import t, { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateTileForm = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('headerFieldCantBeEmpty');
  }
  if (!values.headingImage) {
    errors.headingImage = p('picFieldCantBeEmpty');
  }

  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }
  if (!values.publicationDate) {
    errors.publicationDate = p('publicationDateFieldCantBeEmpty');
  }
  if (!values.content) {
    errors.content = p('fieldEmpty');
  }
  if (!values.contentType) {
    errors.contentType = p('fieldEmpty');
  }
  if (values?.content?.value === 'AUCTIONS' && !values.contentType) {
    errors.contentType = p('fieldEmpty');
  }
  if (values?.content?.value === 'AUCTIONS' && !values.subContentType) {
    errors.subContentType = p('fieldEmpty');
  }
  if (values.content?.value === 'EXTERNAL_LINK' && !values?.contentType?.includes('http')) {
    errors.contentType = t('CompanyForm.websiteUrlTooltip');
  }
  if (values.content?.value === 'YOUTUBE_VIDEO' && !values?.contentType) {
    errors.contentType = t('NewsForm.youTubeError');
  }
  if (values.publicationDate && values.expirationDate) {
    if (moment(values.expirationDate).isBefore(moment(values.publicationDate))) {
        errors.expirationDate = p('endBeforeStart');
    }
  }
  return errors;
};
export default validateTileForm;

