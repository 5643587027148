import React, { useEffect, useState } from 'react';
import { reduxForm, getFormValues, Field } from 'redux-form';
import {
  Table,
  Button, Col, Row,
} from 'reactstrap'; import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import moment from 'moment';
import { splitEvery } from 'ramda';
import assignUserToTierValidate from './assignUserToTierValidate';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import RenderFileInputField from '../../../shared/components/form/FileInput';
import FormBox from '../../../shared/components/custom/FormBox';
import { capitalizeFirstLetter } from '../../../util/functions';
import useConfig from '../../../util/useConfig';

const s = partial('shared');

const UploadTierCSVPage = ({ tierID, getTierUsers, changeTab }) => {
  const config = useConfig();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [csvUsers, setCSVUsers] = useState([]);

  const checkUsers = async () => {
    setLoading(true);
    try {
      const usernames = csvUsers.map((usr) => usr.username);
      const chunked = splitEvery(300, usernames);
      const datebaseUsers = (await Promise.all(
        chunked.map((partialUsernames) =>
        Api.users.findByPhoneNumbers(partialUsernames)))).reduce((tot, curr) => ({ ...tot, ...curr }), {});
      const allUsersWithMarker = csvUsers.map((usr) => {
        const foundUserCloudId = datebaseUsers[usr.username];
        return {
          ...usr,
          existsInDatebase: !!foundUserCloudId,
          cloudId: foundUserCloudId,
        };
      });
      setUsers(allUsersWithMarker);
      setLoading(false);
    } catch (e) {
      toast.error(t('UsersTab.fetchingUsersFailed'));
      setLoading(false);
    }
  };
  const convertCSVToArray = (csvData) => {
    const COLUMN_NAMES = ['firstName', 'lastName', 'phone', 'tierName', 'dueDate'];
    const DELIMITED = ',';
    const DATE_FORMAT = 'YYYY-MM-DD';
    const lines = csvData.split(/\r\n|\n/);
    const linesWithoutHeader = lines.slice(1, lines.length);
    const splitLines = linesWithoutHeader.map((line) => line.split(DELIMITED).filter((val) => val !== ''));
    const faultyLines = splitLines.map((line, idx) => ({ len: line.length, idx })).filter((item) => item.len !== COLUMN_NAMES.length);
    if (faultyLines.length > 0) {
      const toManyLines = faultyLines.filter((line) => line.len > COLUMN_NAMES.length);
      const toFewLines = faultyLines.filter((line) => line.len < COLUMN_NAMES.length);
      let totalText = '';
      if (toManyLines.length > 0) {
        const toManyLineNumbers = toManyLines.reduce((tot, curr, currIdx) => `${tot}${curr.idx}${currIdx !== toManyLines.length - 1 ? ',' : ''}`, '');
        totalText += `${t('UsersTab.youHaveTooManyValuesInLines')}: ${toManyLineNumbers}. `;
      }
      if (toFewLines.length > 0) {
        const toFewLineNumbers = toFewLines.reduce((tot, curr, currIdx) => `${tot}${curr.idx}${currIdx !== toFewLines.length - 1 ? ',' : ''}`, '');
        totalText += `${t('UsersTab.youHaveTooFewValuesInLines')}: ${toFewLineNumbers}.`;
      }
      toast.error(totalText);
      setUsers([]);
      return [];
    }
    const objectsArray = splitLines.map((arr) => arr.reduce((tot, curr, idx) => Object.assign(tot, { [COLUMN_NAMES[idx]]: curr }), {}));
    const mappedUsers = objectsArray.map((csvUser) => {
      const onlyUsername = csvUser.phone.replace(' ', '');
      return ({
        username: onlyUsername,
        ...csvUser,
      });
    });
    const containsInvalidDate = mappedUsers.some((item) => !moment(item.dueDate, DATE_FORMAT, true).isValid());
    if (containsInvalidDate) {
      toast.error(t('UsersTab.dataContainsWrongDates'));
      setUsers([]);
      return [];
    }
    return mappedUsers;
  };
  useEffect(() => {
    if (csvUsers.length > 0) {
      checkUsers();
    }
  }, [csvUsers]);
  const renderClassNameRows = (disabled) => {
    if (disabled) {
      return 'listDisabled transitionDuration-03 center-list';
    }
    return 'transitionDuration-03 overflow-visible-hard';
  };
  const listUsers = () => {
    return users.map((user) => {
      const { existsInDatebase } = user;
      const phoneColor = !existsInDatebase ? { color: 'white' } : undefined;
      return (
        <tr key={`User-${user.username}`} className={renderClassNameRows(!existsInDatebase)}>
          <td className="checkbox-position">
            {existsInDatebase
              ? <CheckboxMarkedCircleIcon color="#399351" />
              : <CloseCircleIcon color="#FF4861" />
            }
          </td>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>
            {
              existsInDatebase ?
                <Link to={`/users/edit/${user.id}`} style={phoneColor}>{user.username}</Link> :
                <div style={phoneColor}>{user.username}</div>
            }
          </td>
          <td>{user.tierName}</td>
          <td>{user.dueDate}</td>
        </tr>
      );
    });
  };
  const handleAddCsv = ({ file }) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const convertedUsers = convertCSVToArray(e.target.result);
      setCSVUsers(convertedUsers);
    };
    reader.readAsText(file);
  };
  const uploadCSV = async () => {
    setLoading(true);
    try {
      const usersWithDates = users.map((usr) =>
        usr.existsInDatebase
          ? { cloudId: usr.cloudId, dueDate: usr.dueDate }
          : { phoneNumber: usr.phone, dueDate: usr.dueDate },
      );
      await Api.tiers.assignUsersFromCSV({ users: usersWithDates, tierID }, config);
      getTierUsers();
      changeTab('2');
      setLoading(false);
      toast.success(t('EditUserPage.userWasUpdated'));
    } catch (e) {
      toast.error(t('UsersTab.fetchingUsersFailed'));
      setLoading(false);
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <div className="form mb-3" >
        <Row className="width-100p">
          <Col>
            <Field
              name="csvFile"
              component={RenderFileInputField}
              onChange={handleAddCsv}
            />
            <Button color="primary" size="sm" disabled={!csvUsers.length} onClick={uploadCSV}>Upload</Button>
          </Col>
          <FormBox title="Tooltip">
            <Col className="pb-3">
              <p>Format of the csv should be coma(,) separated with a linebreak at the end of each line</p>
              <p>Firstname,Lastname,PhoneNumber,Membership name, Membership enddate</p>
              <p>Countrycode should awlays start with + ex(+46/+47)</p>
            </Col>
          </FormBox>
        </Row>
      </div>
      {users.length > 0 &&
        <div style={{ display: 'flex', marginTop: 10, paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped>
            <thead>
              <tr>
                <th>{s('appMember')}</th>
                <th>{s('firstname')}</th>
                <th>{s('lastname')}</th>
                <th>{s('username')}</th>
                <th>{capitalizeFirstLetter(t('MessagesForm.tier'))}</th>
                <th>{s('endDate')}</th>
              </tr>
            </thead>
            <tbody>
              {listUsers()}
            </tbody>
          </Table>
        </div>
      }
    </>
  );
};


const reduxF = reduxForm({
  form: 'upload_tier_csv_page',
  validate: assignUserToTierValidate,
});

const mapStateToProps = state => ({
  formValues: getFormValues('upload_tier_csv_page')(state),
});

export default connect(mapStateToProps)(reduxF(UploadTierCSVPage));
